
<div id="forgot-password"  class="page-layout simple fullwidth" fusePerfectScrollbar>

    <!-- HEADER -->
    <page-header title="Forgot Your Password ?" icon="key" *ngIf="!hideBanner"></page-header>

    <!-- CONTENT -->   
    <div class="content px-24 py-40 forgot-password">
        <div id="forgot-password-form-wrapper">
            <div id="forgot-password-form" *ngIf="forgotPasswordForm">

                <form name="forgotPasswordForm" [formGroup]="forgotPasswordForm" novalidate fxLayout="column" fxLayoutAlign="space-between center">
                
                    <mat-form-field appearance="outline">
                        <mat-label>Your Email Address</mat-label>
                        <input matInput formControlName="email">
                        <!-- <tinc-icon matSuffix  class="secondary-text" icon="mail"></tinc-icon> -->
						
						<mat-icon matSuffix>mail</mat-icon>
                        <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
                            Email is required
                        </mat-error>
                        <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
                            Please enter a valid email address
                        </mat-error>
                    </mat-form-field>
                
                    <button mat-raised-button class="submit-button accent mt-12" aria-label="SEND RESET LINK"
                        [disabled]="forgotPasswordForm.invalid" (click)="sendResetLink()">
                        SEND RESET LINK
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>