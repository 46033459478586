import { Component, OnInit, ViewEncapsulation, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import  {MatDialog, MatDialogRef,  MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { MembershipService } from '@shared/services/membership/membership.service';


import { Router, ActivatedRoute } from '@angular/router';
import { AlertType } from '@shared/services/notification/alertType';


import { FuseAlertDialogComponent } from '@fuse/components/alert-dialog/alert-dialog.component';
import { NotificationService } from '@shared/services/notification/notification.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@tinc/components/icon/icon.component';
import { MatIconModule } from '@angular/material/icon';
import { PageHeaderComponent } from '@tinc/components/page-header/page-header.component';


export interface DialogData {
    message: string;    
}

@Component({
    selector     : 'forgot-password',
    templateUrl  : './forgot-password.component.html',
    styleUrls    : ['./forgot-password.component.scss'],
	animations: fuseAnimations,
	standalone: true,
	imports: [ ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, CommonModule, IconComponent, MatDialogModule, PageHeaderComponent ,MatIconModule]
})
export class ForgotPasswordComponent implements OnInit
{
	@Input() hideBanner: boolean = false;
    forgotPasswordForm: FormGroup;    
	errorMessage: string;
    // confirmDialogRef: MatDialogRef<FuseAlertDialogComponent>;
    alertDialogRef: MatDialogRef<FuseAlertDialogComponent>;

	// icPage = icPage;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private membershipService: MembershipService,
        public _matDialog: MatDialog,
        private notify: NotificationService,
        private router: Router,
        private route: ActivatedRoute,
    ){}

    ngOnInit(): void
    {
        this.forgotPasswordForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    sendResetLink(){        
        this.membershipService.forgotPassword(this.forgotPasswordForm.value).subscribe(
            (data: any) => {
                this.errorMessage = null;              

                this.alertDialogRef = this._matDialog.open(FuseAlertDialogComponent, {
                    disableClose: false
                });

				this.alertDialogRef.componentInstance.alertMessage = 'If there is an account associated with this email, we will send the password reset instructions immediately.';

                this.alertDialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        this.router.navigateByUrl("/");
                    }
                });                
            },
            err => {
                this.notify.openAlertModal((err.error ? err.error : "An error has occured"), AlertType.ERROR);
            }
        );
    }
}