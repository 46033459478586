@use '@angular/material' as mat;

login {
	.dropdown {
		background: var(--background-card);
		border-bottom-left-radius: var(--border-radius);
		border-bottom-right-radius: var(--border-radius);
		@include mat.elevation(4);
		max-width: 100vw;
		overflow: hidden;
		width: 350px;
	}

	.dropdown-header {
		@include mat.elevation(6);
		padding: 1rem 1rem 1rem 0.75rem;
		border: 2px solid #cdcdcd;
	}

	.dropdown-heading-icon {
		background: rgba(255, 255, 255, 0.2);
		border-radius: 999999px;

		.mat-icon {
			font-size: 32px;
			height: 32px;
			width: 32px;
		}
	}

	.dropdown-content {
		max-height: 390px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	.mat-mdc-form-field-infix{
		display:flex !important;
		align-items:center !important;
	}

	form {
		width: 100%;
		text-align: left;

		mat-form-field {
			width: 100%;
		}
		
		mat-checkbox {
			margin: 0;
		}

		.remember-forgot-password {
			font-size: 13px;

			.forgot-password {
				font-size: 13px;
				font-weight: 600;
				margin-bottom: 8px;
			}
		}

		.submit-button {
			width: 220px;
			margin: 0px auto;
			display: block;

			@media screen and (max-width: 599px) {
				width: 90%;
			}
		}
	}

	.register {
		margin: 8px auto;
		font-weight: 600;

		.text {
			margin-right: 8px;
		}
	}

	.separator {
		font-size: 15px;
		font-weight: 600;
		margin: 24px auto;
		position: relative;
		overflow: hidden;
		width: 100px;

		.text {
			display: inline-flex;
			position: relative;
			padding: 0 8px;
			z-index: 9999;

			&:before,
			&:after {
				content: '';
				display: block;
				width: 30px;
				position: absolute;
				top: 10px;
				border-top: 1px solid;
			}

			&:before {
				right: 100%;
			}

			&:after {
				left: 100%;
			}
		}
	}

	button {
		&.google,
		&.facebook {
			width: 192px;
			text-transform: none;
			color: #ffffff;
			font-size: 13px;
		}

		@media screen and (max-width: 599px) {
			width: 80%;
		}

		&.google {
			background-color: #d73d32;
			margin-bottom: 8px;
		}

		&.facebook {
			background-color: rgb(63, 92, 154);
		}
	}
}

body.theme-deep-orange .accent[disabled] {
	color: #a52a2a78 !important;
}


input[type="email"],
input[type="number"],
input[type="password"],
input[type="text"],
select:focus,
textarea {
  font-size: 16px;
}


