import { Component, OnInit, TemplateRef, Type } from '@angular/core';
import { PopoverContent, PopoverRef } from './popover-ref';
import { popoverAnimation } from '@tinc/animations/popover.animation';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';

@Component({
	templateUrl: './popover.component.html',
	styleUrls: ['./popover.component.scss'],
	animations: [ popoverAnimation ],
	standalone: true,
	imports: [ CommonModule,OverlayModule ]
})
export class PopoverComponent implements OnInit {
	renderMethod: 'template' | 'component' | 'text' = 'component';
	content: PopoverContent;
	template4Container: Type<any>;
	context;

	constructor(private popoverRef: PopoverRef) {
	}

	ngOnInit() {
		this.content = this.popoverRef.content;
		this.template4Container = this.content as Type<any>;
		
		if (typeof this.content === 'string') {
			this.renderMethod = 'text';
		}

		if (this.content instanceof TemplateRef) {
			this.renderMethod = 'template';
			this.context = {
				close: this.popoverRef.close.bind(this.popoverRef)
			};
		}

	}
}
