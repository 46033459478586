<div @transformPopover class="popover">
<!--<ng-container [ngSwitch]="renderMethod">

     <div *ngSwitchCase="'text'" [innerHTML]="content"></div> -->

    <!-- <ng-container *ngSwitchCase="'template'">
      <ng-container *ngTemplateOutlet="template4Container; context: context"></ng-container>
    </ng-container> 

    <ng-container *ngSwitchCase="'component'"> -->
      <ng-container *ngComponentOutlet="template4Container"></ng-container>
    <!-- </ng-container>
  </ng-container> -->
</div>
